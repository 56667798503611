import React, {ReactNode,useRef} from 'react';
import styled from "styled-components";


import anim1 from "../../../assets/video/amim1.mp4";
import anim2 from "../../../assets/video/anim2.mp4";


function VideoWait({...props}: {}) {
    const videoRef = useRef<HTMLVideoElement>(null);
    const onEnd = () => {
        setTimeout(() => {
            videoRef.current?.play();
              videoRef.current?.setAttribute("src", videoRef.current?.getAttribute("src") === anim1 ? anim2 : anim1);
              videoRef.current?.load();

          }, 15000); // 2 seconds delay
    }
    return (
      <div style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100vw",
        background: "linear-gradient(to bottom, transparent, #fff,#fff,#fff,#fff,#fff, #fff)",
        paddingTop: "100px",
        }}>
          <video
          ref={videoRef}
          autoPlay
          muted
          playsInline
          style={{
        width: "100%",
        objectFit: "cover",
        zIndex: 10,
          }}
          onEnded={() => {
        onEnd()
          }}
          >
        <source src={anim1} type="video/mp4" />
        
        Your browser does not support the video tag.
        </video>
      </div>
    );
}

export default VideoWait;
