import React, {useMemo} from 'react';
import i18next from 'i18next';
import styled from "styled-components";
import {HeadphoneIcon} from "../../UI/Svg";
import {useAppState} from "../../store/app/hooks";
import {useUserState} from "../../store/user/hooks";
import {IScore} from "../../store/user/types";

const Wrapper = styled.div`
  width: 100%;
  max-width: fit-content;
  border-radius: 30px;
  overflow: hidden;
  background: #241F21;
  min-width: 260px;
  padding-bottom: 0;
`

const Statistic = styled.div`
  padding: 23px 10px 22px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 7px;
`



const Row = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding-left: 8px;
  width: 100%;
  gap: 12px;
  min-width: 200px;
  border-bottom: 1px solid #494547;
`

const Name = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  color: rgba(255, 255, 255, 0.8);
`

const Value = styled.div`
  min-width: 35px;
  padding: 2px;
  height: 37px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.9);
`

const Button = styled.a`
  width: 100%;
  padding: 15px 2px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.92);
  background: linear-gradient(90deg, #FDC558 0%, #FCA35D 17.71%, #FC9271 34.9%, #FD889F 49.48%, #FD82C3 65.62%, #E177D1 82.81%, #AA65F6 99.48%);
  border: none;
  outline: none;
  margin-bottom: -1px;
  &:active {
    opacity: .8;
  }
`

const typeLink = 'card'
function UserStatisticComponent({setLoading}:any) {
    const {token} = useAppState()
    const {user} = useUserState()
    const loader = () => {
      setLoading(true)
    }

    const score: IScore | null = useMemo(() => user?.score || null, [user])
    return (
        <Wrapper>
            <Statistic>
              {/* количество сумок */}
                <Row>
                    <Name>{i18next.t('user_statistic.amount')}</Name>
                    <Value>{score?.total || 0} шт</Value>
                </Row>
               

              {/* всего заработано */}
                <Row>
                    <Name>{i18next.t('user_statistic.total_profit')}</Name>
                    <Value style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'end'}}>
                      <span>{score?.active_profit?.toLocaleString('ru')  || 0} D </span><span style={{opacity:0.5,marginLeft:'4px'}}>
                     ~ {score?.active_profit_rub || 0} руб. </span></Value>
                </Row>

                {/* <Row>
                    <Name>{i18next.t('user_statistic.passive_profit')}</Name>
                    <Value>{score?.passive_profit || 0} $</Value>
                </Row> */}
                {/* заработано за сегодня */}
                <Row>
                    <Name>{i18next.t('user_statistic.revenue_today')}</Name>
                    <Value>{score?.revenue_today?.toLocaleString('ru')  || '0'} D</Value>
                </Row>

                <Row  style={{border:'none'}}>
                    <Name>{i18next.t('user_statistic.active')}</Name>
                    <Value>~ {score?.body ? Math.round(score?.body).toLocaleString('ru') : 0} $</Value>
                </Row>
                
                {/* <Row> */}
                {/*    <Name>Доступно для вывода</Name> */}
                {/*    <Value>{score?.available || 0}$</Value> */}
                {/* </Row> */}
            </Statistic>

          <Button onClick={(event) => {
                loader()
            }} href={`https://app.marketgame.space/api/wallet/withdraw-${typeLink}?user=${user?.uid}&token=${token}`}>{i18next.t('user_statistic.withdrawl')} 
            <strong style={{
              marginLeft:5,
              fontSize:22
            }}>{score?.available?.toLocaleString('ru') || 0} D</strong>
            </Button>
        </Wrapper>
    );
}

export default UserStatisticComponent;
