import React, {ReactNode,useRef,useState} from 'react';
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import ReactPlayer from 'react-player';
import CaptionAnimation from "./CaptionAnimation";

import education from "../../../assets/video/education.mp4";


function VideoEdu({ howToPlay, onStop, ...props }: { howToPlay: boolean, onStop: () => void }) {
  const [currentCaption, setCurrentCaption] = useState("");
  const DELAY = -1; // Задержка в секундах

  const captions = [
    { start: 2, end: 3, text: "СЕЙЧАС РАССКАЖУ" },
    { start: 3, end: 8, text: "Еще секунду" },
    { start: 9, end: 14, text: "Смотри деньги падают" },
    { start: 15, end: 16, text: "Хм Деньги" },
    { start: 16, end: 17, text: "не могут падать" },
    { start: 17, end: 19, text: "с неба?" },
    { start: 19, end: 20, text: "Интересно," },
    { start: 20, end: 21, text: "А это тогда что?" },
    { start: 21, end: 22, text: "Ну ка давай" },
    { start: 22, end: 23, text: "нажми на сумку" },
    { start: 23, end: 24, text: "и попробуй половить их" },
    { start: 24, end: 26, text: "Давай попробуй их" },
    { start: 26, end: 27, text: "Видишь, получается" },
    { start: 27, end: 28, text: "И я тебе больше что" },
    { start: 28, end: 29, text: "скажу эти деньги" },
    { start: 29, end: 30, text: "Которые сейчас падают" },
    { start: 30, end: 32, text: "С Неба - НАСТОЯЩИЕ" },
    { start: 32, end: 33, text: "То есть ты можешь" },
    { start: 33, end: 34, text: "Сейчас взять половить" },
    { start: 34, end: 35, text: "А потом в личном кабинете" },
    { start: 35, end: 36, text: "Нажать кнопочку" },
    { start: 36, end: 37, text: "Вывести и вывести их" },
    { start: 37, end: 38, text: "К себе на карту" },
    { start: 38, end: 39, text: "Либо на крипто" },
    { start: 39, end: 40, text: "Кошелек" },
    { start: 40, end: 42, text: "Просто моментально" },
    { start: 42, end: 43, text: "Все что тебе нужно" },
    { start: 43, end: 44, text: "Сделать это" },
    { start: 44, end: 45, text: "Нажать кнопку СТАРТ" },
    { start: 45, end: 46, text: "И" },
    { start: 46, end: 47, text: "После нажатия" },
    { start: 47, end: 49, text: "СТАРТ" },
    { start: 49, end: 50, text: "Начнется игра" },
    { start: 50, end: 51, text: "Но будь внимателен у тебя есть" },
    { start: 51, end: 53, text: "Всего 3 жизни" },
    { start: 53, end: 54, text: "То есть если 3 монеты" },
    { start: 54, end: 55, text: "Упадут мимо" },
    { start: 55, end: 56, text: "Твоей сумки" },
    { start: 56, end: 57, text: "все, твоя выручка" },
    { start: 57, end: 58, text: "ЗА сегодня" },
    { start: 58, end: 59, text: "Собранная" },
    { start: 59, end: 61, text: "Она полностью сгорит" },
    { start: 61, end: 62, text: "И есть один момент" },
    { start: 62, end: 63, text: "Твоя сумка на резиновая" },
    { start: 63, end: 64, text: "Поэтому" },
    { start: 64, end: 65, text: "Будь" },
    { start: 65, end: 67, text: "Аккуратен" },
    { start: 67, end: 68, text: "У нее есть емкость" },
    { start: 68, end: 69, text: "И когда емкость наполнится" },
    { start: 69, end: 70, text: "Уже" },
    { start: 70, end: 71, text: "Сегодня" },
    { start: 71, end: 72, text: "Ты не сможешь собирать деньги" },
    { start: 72, end: 73, text: "Нужно ждать обновления емкости" },
    { start: 73, end: 74, text: "На следующий" },
    { start: 74, end: 76, text: "День" },
    { start: 76, end: 77, text: "А если хочешь заработать" },
    { start: 77, end: 78, text: "Больше, то просто" },
    { start: 78, end: 79, text: "Купи себе в личном кабинете" },
    { start: 79, end: 80, text: "Вертуальную сумку" },
    { start: 80, end: 81, text: "И у нее будет" },
    { start: 81, end: 82, text: "Огромная вместимость" },
    { start: 82, end: 83, text: "И ты сможешь" },
    { start: 83, end: 84, text: "Сколько хочешь ловить" },
    { start: 84, end: 85, text: "Денег" },
    { start: 85, end: 87, text: "И у нее жизней больше" },
    { start: 87, end: 89, text: "Добро пожаловать" },
    { start: 89, end: 90, text: "В партию деньги" },
    { start: 90, end: 91, text: "Давай вместе" },
    { start: 91, end: 93, text: "Делать деньги" },
    { start: 93, end: 95, text: "На наших сумках" },
    { start: 95, end: 96, text: "Изучай приложения" },
    { start: 96, end: 97, text: "Мы делаем" },
    { start: 97, end: 99, text: "Революцию" },
  ];
  const videoRef = useRef<ReactPlayer>(null);


  const handlePlayPause = () => {
    if (howToPlay) {
      videoRef.current?.seekTo(1, 'seconds');
      videoRef.current?.getInternalPlayer()?.play();
    } else {
      videoRef.current?.getInternalPlayer()?.pause();
    }
  };


    
  React.useEffect(() => {
      videoRef.current?.getInternalPlayer()?.load();
    }, []);

  React.useEffect(() => {
    handlePlayPause();
  }, [howToPlay]);

  const handleProgress = ({ playedSeconds }) => {
    console.log(playedSeconds,howToPlay);
    if (howToPlay===false) {
      videoRef.current?.getInternalPlayer()?.pause();
    }
    const correctedTime = playedSeconds - DELAY; // Корректируем время
    const caption = captions.find(
      (c) => correctedTime >= c.start && correctedTime <= c.end
    );
    setCurrentCaption(caption ? caption.text : "");
    
  };

    return (
      <div style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        objectFit: "cover",
        zIndex: 0,
        backgroundColor: "white",
        visibility: howToPlay ? "visible" : "hidden",
      }}>
      {/* Видео */}
      <ReactPlayer
        ref={videoRef}
        url='https://firebasestorage.googleapis.com/v0/b/partiyadengi.firebasestorage.app/o/121212.mp4?alt=media&token=179cfbc2-0afe-42a2-abf0-85edf979cc18'
        playing
        width="100%"
        height="100%"
        onProgress={handleProgress}
        onEnded={onStop}
        config={{
          file: {
            attributes: {
              controlsList: "nodownload",
              autoPlay: true,
              preload: "auto",
              playsInline: true,
            },
          },
        }}
      />





        {howToPlay && videoRef.current?.getCurrentTime() < 1 && (
          <div style={{ 
            position: "fixed", 
            top: "50%", 
            left: "50%", 
            transform: "translate(-50%, -50%)", 
            color: "rgba(0,0,0,0.4)", 
            fontSize: "1.5rem", 
            fontWeight: "bold", 
            width: "100%",
            textAlign: "center"
          }}>
            ЗАГРУЖАЮ  <br />
            ОБУЧЕНИЕ...
          </div>
        )}

        {/* Титры */}
      <div className="">
        <AnimatePresence mode="wait">
          {currentCaption && (
            <motion.div
              key={currentCaption}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
              style={{color: "rgba(0,0,0,0.4)", fontSize: "1.5rem", fontWeight: "bold", position: "fixed", top: "50%",transform: "translate(-50%, -50%)", width: "100%", textAlign: "center"}}
            >
              {currentCaption}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
     

    );
}

export default VideoEdu;
