import React, {useState,useEffect,useRef, useMemo} from 'react';
import {Link, useSearchParams,useNavigate} from "react-router-dom";
import i18next, { use } from 'i18next';
import Lottie from "react-lottie-player";
import styled from "styled-components";
// @ts-ignore
import Picker from 'react-mobile-picker'
import { swipeBehavior,on,hapticFeedback,init,closingBehavior,themeParams,themeParamsHeaderBackgroundColor} from '@telegram-apps/sdk';
import SlideButton from 'react-slide-button';
import {useAppAction, useAppData, useAppState} from "../../store/app/hooks";
import lottieJson from "../../lottie.json";
import {HeartIcon,HeartRedIcon} from "../../UI/Svg";
import {AppApi} from "../../api/app";

import {useUserDataOnce,useUserData, useUserState,useUserAction} from"../../store/user/hooks";
import {formatTime} from "../../utils";
import bg from "../../assets/images/BG.png";
import freebag from "../../assets/images/freebag.png";
import map from "../../assets/images/map.png";
import { Image,DivImage,ImageCatcher, Wrapper, Map, Money, MoneyHeader,
  ChooseYourBagToPlay,ImageCatcherWrapper,
  PickerChooseItem,StartGame,GradientText,GradientTextBlack,PauseBTN } from './styled';
import PreloaderMax from"../../views/RefViews/PreloaderMax";
import Header from "../../components/Header";
import "../../styles/FallingGame.css";
import VideoWait from "./components/VideoWait";
import VideoEdu from "./components/VideoEdu";

import {
  HeaderComponent,
} from"../../components/EduComponents";

interface FloatingText {
	id: number;
	x: number;
	y: number;
}

interface TMyPod {
  id: number;
  slug: string;
  image: string;
  capacity: number;
  today: {
    value: number;
    hp_day: number;
  };
}



function Index() {
    useUserDataOnce()
    const [menuShow, setShowMenu] = useState(false);
    const {getUserData} = useUserAction()
    const {user} = useUserState()
    const {token} = useAppState()
    const [alpha, setAlpha] = useState(0); // Угол вокруг Z-оси
    const [beta, setBeta] = useState(0);   // Угол вокруг X-оси
    const [gamma, setGamma] = useState(0); // Угол вокруг Y-оси
	  const [floatingTexts, setFloatingTexts] = useState<FloatingText[]>([]);
    const [nextId, setNextId] = useState(0);
    const [isGameStaerted, setGameStarted] = useState(false);

    const [loading, setLoading] = useState(true);

    const [score, setScore] = useState(0);
    const [batchScore, setbatchScore] = useState(0);
    const [revenueStep,setRevenueStep] = useState(1);
    const navigate = useNavigate();

    const [loosses, setLoose] = useState(0);
    
    const [NoMoreEduaction, SetNoMoreEduaction] = useState(false);
    const [howToPlay, setHowToPlay] = useState(true);
    const [pauseVar, setPauseVar] = useState(false);
    const [pickerValue, setPickerValue] = useState({ bag: 0})

    const gameContainerRef = useRef(null);
    const catcherRef = useRef(null);


    const [deltaVelocity, setDeltaVelocity] = useState(5);
    const [widthPlatform, setWidthPlatforn] = useState(45);


    const [countdown, setCountdown] = useState(-1); // до начала игры 
    
    const [gameTime, setGameTime] = useState(0); // время игры
    const [currentBag, setCurrentBag] = useState<TMyPod | null>(null); // 

 


    
    useEffect(() => { 
      if(isGameStaerted) return
      let podc = user?.mypods.find(pod => pod.id === pickerValue.bag)
      setScore(podc?.today?.value || 0)
      setCurrentBag(podc)
    }, [pickerValue, user]);


    const activePod = useMemo(() => user?.mypods.find(pod => pod.today.hp_day > 0), [user]);
     
    useEffect(() => {
      let interval: any
      if (loading) {
          interval = setInterval(() => {
              setLoading(false)
          }, 5000)
      }
  }, [loading])

    

  

    // отбивка проигрша и выйгрыша 
 



    useEffect(() => { 
      // эту штука запускает игру по таймеру 
      if (countdown<1 && countdown > -1 && isGameStaerted === false) {
        setGameStarted(true);
        const gameTimeInterval = setInterval(() => {
          setGameTime((prevTime) => {
          if (prevTime<=-2) {
            clearInterval(gameTimeInterval);
          }
          return prevTime + 1
          });
     
        }, 1000);
      }
      else if (countdown<2 && countdown > -1 && isGameStaerted === false) {
        document.querySelectorAll(".object").forEach((object) => object.remove());
      }
    }, [countdown]);



    function closeEducation(){
      navigate(`/game?token=${token}`);
      
    }

    useEffect(() => {
      if (howToPlay===false){
        closeEducation()
      }
    }, [howToPlay])


    useEffect(() => {

      AppApi.getMeta({
        'bag_id': 1,
        'status': 'got_intro',
        'score': 0,
        'score_delta': 0,
        'secure': 'huudekkkcc'
      }, token).then((meta) => {
          // Update game state based on meta
      });

    }, [user]);

    
    function doHaptic(force){
      if(hapticFeedback?.isSupported()){
        hapticFeedback?.impactOccurred(force)
      }
    }
    

    useEffect(() => {
      if (user?.uid){
        setLoading(false)
      }
      if (isGameStaerted) return
        if (user?.mypods && user?.mypods.length > 0) {
      
        if (activePod) {
          setPickerValue({ bag: activePod.id });
        }
        else{
          setPickerValue({ bag: -1 });

        }
      }

    }, [user]);



 
    


   

    useEffect(() => {
      const gameContainer = gameContainerRef.current;
      const catcher = catcherRef.current;

      const handleMouseMove = (event) => {
        const containerWidth = gameContainer.offsetWidth;
        let mouseX = event.clientX;
        if (mouseX > containerWidth - 40) mouseX = containerWidth - 40;
            catcher.style.left = `${mouseX}px`;
          };

      // Проверка наличия элемента
      if (catcher ) {
          document.addEventListener("touchmove", (event) => {
            const touch = event.touches[0];
            handleMouseMove({ clientX: touch.clientX });
          });
      }


    const createObject = () => {
      const object = document.createElement("div");
      object.classList.add("object");
      object.style.left = `${Math.random() * (gameContainer.offsetWidth - 50)}px`;
      gameContainer.appendChild(object);
      const speed = Math.random() * deltaVelocity + 2; // Random speed between 3 and 10
      let objectTop = 0;
      
      const fallInterval = setInterval(() => {
        objectTop += speed;
        object.style.top = `${objectTop}px`;
        
        if (document.hidden || countdown > 1) {
          clearInterval(fallInterval);
          object.remove();
          return;
        }

        if (catcherRef.current) {
          const catcherRect = catcherRef.current.getBoundingClientRect();
          const objectRect = object.getBoundingClientRect();

          if (
            objectRect.bottom >= catcherRect.top &&
            objectRect.bottom - catcherRect.top < widthPlatform &&
            objectRect.left >= catcherRect.left &&
            objectRect.right <= catcherRect.right
          ) {
            
            setScore((prevScore) => prevScore + revenueStep);
            setbatchScore((prevScore) => prevScore + revenueStep); 

            clearInterval(fallInterval);

            setNextId((previousNextId) => {
              const newId = previousNextId + 1;
              let newFloat = { id: newId, x: 0, y: 0 };
              setFloatingTexts((prev) => [...prev, newFloat]);
              return newId;
            });
      
            object.remove();
            doHaptic('light');
          }
          
          
        }

        if (objectTop > window.innerHeight ) {
          if (isGameStaerted) {
            setLoose((prevLoose) => prevLoose + 1);
            setGameStarted(false);
          }
          clearInterval(fallInterval);
          object.remove();
        }
        
      }, 20);
    };

    const objectInterval = setInterval(createObject, 1000);

    return () => {
      document.removeEventListener("touchmove", handleMouseMove);
      clearInterval(objectInterval);
    };
  }, [isGameStaerted,howToPlay]);

    

    return (
        <Wrapper style={{overflow:'hidden'}}>
          <button type="button" onClick={()=> closeEducation()} style={{
            position: "fixed",
            top: 65,
            right: 30,
            padding: "10px 20px",
            color: "white",
            backgroundColor: "rgba(0,0,0,0.1)",
            fontSize: "1rem",
            borderRadius: "10px",
            fontWeight: "bold",
            cursor: "pointer",
            zIndex: 999,
          }}>закончить обучение</button>

          <VideoEdu howToPlay={howToPlay} onStop={() => setHowToPlay(false)}/>
           

            {/* сумка для игры */}
           { (isGameStaerted || howToPlay ) ? (
              <ImageCatcherWrapper id="catcher" ref={catcherRef}>
                <DivImage style={{ backgroundImage: `url(${freebag})`, }}/>
                <div style={{position:'relative'}}>
                    {floatingTexts.map((text) => (
                      <span
                        key={text.id}
                        className='floating-text'
                        style={{ top: text.y-35, left: text.x+30 }}
                      >
                        <div className='reward-float'>+ {revenueStep} </div>
                    </span>
                  ))}
                </div>
              </ImageCatcherWrapper>) : null
          }
            <div id="gameContainer" style={{}} ref={gameContainerRef}/>
          </Wrapper>
    );
}

export default Index;
