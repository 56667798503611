import React, {useEffect} from 'react';
import i18next from 'i18next';
import { themeParams } from '@telegram-apps/sdk';
import {Route, Routes,useSearchParams,useNavigate} from 'react-router-dom';
import Game from "./pages/Game";
import GameCanvas from "./pages/GameCanvas";
import Edu from "./pages/Edu";

import Index from "./pages/index";
import Token from "./pages/Token";
import {useAppState,useToken} from "./store/app/hooks";
import ResetCSS from "./styles/ResetCSS";
import GlobalStyle from "./styles/Global";
import Map from "./pages/Map";
import Ref from "./pages/Ref";
import {AppUser} from "./api/user";
import Director from './pages/Director';


function App() {
    const [searchParams] = useSearchParams();
    const lang = searchParams.get('lang') || 'ru';

   useEffect(() => {
      i18next.changeLanguage(lang, (err, t) => {
            if (err) return console.log('something went wrong loading', err);
         });

        


   }, []);
    
   useToken()
 
   
  


    return (
        <>
            <ResetCSS/>
            <GlobalStyle/>
            <Routes>
                <Route path="/" element={<Index/>}/>
                <Route path="/token" element={<Token/>}/>
                <Route path="/edu" element={<Edu/>}/>
                <Route path="/game" element={<GameCanvas/>}/>
                <Route path="/game-canvas" element={<Game/>}/>
                <Route path="/ref" element={<Ref/>}/>
                <Route path="/map" element={<Map/>}/>
                <Route path="/director" element={<Director/>}/>
                
            </Routes>
        </>
    )
}

export default App;
