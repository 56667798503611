import React, { useEffect, useRef, useState, useMemo } from "react";
import Picker from 'react-mobile-picker';
import {Link, useSearchParams,useNavigate} from "react-router-dom";
import styled from "styled-components";
import { swipeBehavior, on, hapticFeedback, init, closingBehavior, themeParams, themeParamsHeaderBackgroundColor } from '@telegram-apps/sdk';
import { Img, DivImage, ImageCatcher, Wrapper, Map, Money, MoneyHeader, ChooseYourBagToPlay, ImageCatcherWrapper, PickerChooseItem, StartGame, GradientText, GradientTextBlack, PauseBTN } from './styled';
import { useAppAction, useAppData, useAppState } from "../../store/app/hooks";
import { AppApi } from "../../api/app";
import { useUserDataOnce, useUserData, useUserState, useUserAction } from "../../store/user/hooks";
import { HeartIcon, HeartRedIcon } from "../../UI/Svg";
import VideoWait from "./components/VideoWait";
import VideoEdu from "./components/VideoEdu";
import loseSound from "../../assets/audio/loose.mp3"
import Header from "../../components/Header";

const drawFlyingMoney = (ctx, amount, x, y, opacity) => {
  ctx.font = '24px Arial';
  ctx.fillStyle = `rgba(0, 128, 0, ${opacity})`;
  ctx.fillText(`+${amount}`, x, y);
};

const CanvasWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const PauseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  background-color: #ff5f5f;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
`;

const LoadingScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  z-index: 20;
`;

interface GameObject {
  x: number;
  y: number;
  speed: number;
}

interface FloatingText {
  id: number;
  x: number;
  y: number;
}

interface TMyPod {
  id: number;
  slug: string;
  image: string;
  capacity: number;
  today: {
    value: number;
    hp_day: number;
  };
}

const GameCanvas: React.FC = () => {
  useUserDataOnce();
  const { getUserData } = useUserAction();
  const { user } = useUserState();
  const { token } = useAppState();
    const navigate = useNavigate();

  const [menuShow, setShowMenu] = useState(false);
  const [alpha, setAlpha] = useState(0);
  const [beta, setBeta] = useState(0);
  const [gamma, setGamma] = useState(0);
  const [floatingTexts, setFloatingTexts] = useState<FloatingText[]>([]);
  const [nextId, setNextId] = useState(0);
  const [isGameStarted, setGameStarted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [score, setScore] = useState(0);
  const [batchScore, setBatchScore] = useState(0);
  const [revenueStep, setRevenueStep] = useState(1);
  const [losses, setLosses] = useState(0);
  const [noMoreEducation, setNoMoreEducation] = useState(false);
  const [howToPlay, setHowToPlay] = useState(false);
  const [pauseVar, setPauseVar] = useState(false);
  const [pickerValue, setPickerValue] = useState({ bag: 0 });
  const [deltaVelocity, setDeltaVelocity] = useState(5);
  const [widthPlatform, setWidthPlatform] = useState(45);
  const [countdown, setCountdown] = useState(-1);
  const [gameTime, setGameTime] = useState(0);
  const [currentBag, setCurrentBag] = useState<TMyPod | null>(null);

  const gameContainerRef = useRef(null);
  const catcherRef = useRef(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const catcherX = useRef<number>(window.innerWidth / 2 - 50);
  const gameObjects = useRef<GameObject[]>([]);
  const catcherImage = useRef<HTMLImageElement | null>(null);
  const objectImage = useRef<HTMLImageElement | null>(null);

  const [showMoney, setShowMoney] = useState(false);
  const [animationData, setAnimationData] = useState({ x: 0, y: 0, opacity: 1 });


  const doHaptic = (force: 'light' | 'medium' | 'heavy') => {
    if (hapticFeedback?.isSupported()) {
      hapticFeedback?.impactOccurred(force);
    }
  };

  const playLoseSound = () => {
    const audio = new Audio(loseSound);
    audio.play();
};


  useEffect(() => {
    if (isGameStarted) return;
    let selectedPod = user?.mypods.find(pod => pod.id === pickerValue.bag);
    setScore(selectedPod?.today?.value || 0);
    setCurrentBag(selectedPod);
  }, [pickerValue, user]);

  const activePod = useMemo(() => user?.mypods.find(pod => pod.today.hp_day > 0), [user]);

  useEffect(() => {
    if (user?.uid) {
      setLoading(false);
    }
    if (isGameStarted) return;
    if (user?.mypods && user?.mypods.length > 0) {
      if (activePod) {
        setPickerValue({ bag: activePod.id });
      } else {
        setPickerValue({ bag: -1 });
      }
    }
  }, [user]);

  const pause = () => {
    setPauseVar(true);
    gameObjects.current = [];
    setGameStarted(false);
    setCountdown(-1);
  };

  const playGame = (status: string) => {
    if (pauseVar) return;
    if (token) {
      AppApi.getMeta({
        'bag_id': pickerValue.bag,
        'status': status,
        'score': score,
        'score_delta': batchScore,
        'secure': 'huudekkkcc'
      }, token).then((meta) => {
          setBatchScore(0)
          // Update game state based on meta
      });
    }
  };

  const setSettingBeforeGame = () => {
    switch (currentBag?.slug) {
      case 'free':
        setWidthPlatform(130);
        setRevenueStep(1);
        break;
      case 'smallbag':
        setWidthPlatform(80);
        setRevenueStep(2);
        break;
      case 'mediumbag':
        setWidthPlatform(50);
        setRevenueStep(50);
        break;
      default:
        setWidthPlatform(45);
        setRevenueStep(1);
        break;
    }
  };

  useEffect(() => {
    if (countdown < 1 && countdown > -1 && !isGameStarted) {
      setGameStarted(true);
      const gameTimeInterval = setInterval(() => {
        setGameTime(prevTime => {
          if (prevTime <= -2) {
            clearInterval(gameTimeInterval);
          }
          return prevTime + 1;
        });
      }, 1000);
    } else if (countdown < 2 && countdown > -1 && !isGameStarted) {
      gameObjects.current = [];
    }
  }, [countdown]);

  const startGame = () => {
    setBatchScore(0)
    if (!user?.gotIntro && !noMoreEducation) {
      navigate(`/edu?token=${token}`);
      return;
    }
    setSettingBeforeGame();
    setPauseVar(false);
    setCountdown(3);
    setGameTime(0);

    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown > 0) {
          return prevCountdown - 1;
        } 
        if (prevCountdown <= 0) {
          setCountdown(-1)
          clearInterval(countdownInterval);
          return 0;
        }
      });
    }, 1000);
  };

  if (closingBehavior.mount.isAvailable()) {
    closingBehavior.mount();
  }

  if (closingBehavior.enableConfirmation.isAvailable()) {
    closingBehavior.enableConfirmation();
  }

  if (swipeBehavior.mount.isAvailable()) {
    swipeBehavior.mount();
  }

  if (swipeBehavior.disableVertical.isAvailable()) {
    swipeBehavior.disableVertical();
  }

  if (themeParams.mount.isAvailable()) {
    themeParams.mount();
  }

  const updateBagActive = (value: { bag: number }) => {
    setPickerValue({ bag: value.bag });
    doHaptic('light');
  };

  useEffect(() => {
    if (!isGameStarted && gameTime > 3) {
      setGameTime(-3);
      setCountdown(-1)
      playGame('loose');
      getUserData();
    } else if (isGameStarted && gameTime > 3 && gameTime % 3 === 0) {
      playGame('win');
    }
  }, [gameTime]);

  useEffect(() => {
    if (showMoney) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      let animationFrameId;

      const animate = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        drawFlyingMoney(ctx, revenueStep, animationData.x, animationData.y, animationData.opacity);

        setAnimationData(prev => ({
          ...prev,
          y: prev.y - 2,
          opacity: prev.opacity - 0.02
        }));

        if (animationData.opacity > 0) {
          animationFrameId = requestAnimationFrame(animate);
        } else {
          setShowMoney(false);
        }
      };

      animate();

      return () => {
        cancelAnimationFrame(animationFrameId);
      };
    }
  }, [showMoney]);


  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (!canvas || !ctx) return;

    const catcherWidth = 70;
    const catcherHeight = 50;
    const objectRadius = 20;
    const catcherY = canvas.height - catcherHeight - 100;
    let animationFrameId: number;
    let lastTime = performance.now();

    catcherImage.current = new Image();
    catcherImage.current.src = currentBag?.image || "https://pics.clipartpng.com/Black_Bag_PNG_Clip_Art-2944.png";
    catcherImage.current.srcset = `${currentBag?.image || "https://pics.clipartpng.com/Black_Bag_PNG_Clip_Art-2944.png"} 2x`;

    objectImage.current = new Image();
    objectImage.current.src = "https://png.pngtree.com/png-vector/20240323/ourmid/pngtree-cash-paper-banknote-money-cash-heap-pile-and-stack-money-png-image_12192877.png";

    const handleImageLoad = () => {
      if (catcherImage.current.complete && objectImage.current.complete) {
        setLoading(false);
      }
    };

    catcherImage.current.onload = handleImageLoad;
    objectImage.current.onload = handleImageLoad;

    const createObject = () => {
      gameObjects.current.push({
        x: Math.random() * (canvas.width - objectRadius * 2),
        y: 0,
        speed: Math.random() * deltaVelocity + 2,
      });
    };

    const handleTouchMove = (event: TouchEvent) => {
      const touch = event.touches[0];
      catcherX.current = Math.max(
        0,
        Math.min(canvas.width - catcherWidth, touch.clientX - catcherWidth / 2)
      );
    };

    const update = (deltaTime: number) => {
      

      for (let i = 0; i < gameObjects.current.length; i += 1) {
        const obj = gameObjects.current[i];
        obj.y += obj.speed * (deltaTime / 16);

        if (
          obj.y + objectRadius - 30 >= catcherY &&
          obj.y - catcherY < widthPlatform &&
          obj.x + objectRadius - 10 > catcherX.current &&
          obj.x - objectRadius + 10 < catcherX.current + catcherWidth
        ) {
          setScore(prev => prev + revenueStep);
          setBatchScore(prev => prev + revenueStep);
          gameObjects.current.splice(i, 1);
          doHaptic('light');
          setShowMoney(true);
          setAnimationData({ x: obj.x, y: obj.y, opacity: 1 });
          
          i -= 1;
        } else if (obj.y > canvas.height) {
          gameObjects.current.splice(i, 1);
          i -= 1;

          if (isGameStarted) {
            setLosses(prevLosses => prevLosses + 1);
            setGameStarted(false);
          }
          gameObjects.current = [];
        }
      }
    };

    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      if (catcherImage.current) {
        const aspectRatio = catcherImage.current.naturalWidth / catcherImage.current.naturalHeight;
        const dynamicHeight = catcherWidth / aspectRatio;
        ctx.drawImage(
          catcherImage.current,
          catcherX.current,
          catcherY,
          catcherWidth,
          dynamicHeight
        );
      }
      if (objectImage.current) {
        gameObjects.current.forEach(obj => {
          ctx.drawImage(
            objectImage.current,
            obj.x - objectRadius,
            obj.y - objectRadius,
            objectRadius * 2,
            objectRadius * 2
          );
        });
      }
    };

    const gameLoop = (time: number) => {
      const deltaTime = time - lastTime;
      lastTime = time;

      update(deltaTime);
      draw();
      animationFrameId = requestAnimationFrame(gameLoop);
    };

    canvas.addEventListener("touchmove", handleTouchMove);

    const objectInterval = setInterval(createObject, 1000);
    animationFrameId = requestAnimationFrame(gameLoop);

    return () => {
      cancelAnimationFrame(animationFrameId);
      clearInterval(objectInterval);
      canvas.removeEventListener("touchmove", handleTouchMove);
    };
  }, [isGameStarted, score]);

  return (
    <CanvasWrapper>
      <Header show={!isGameStarted ? 'shop':null}/> 
      {!isGameStarted ? <VideoEdu howToPlay={howToPlay} onStop={() => setHowToPlay(false)} /> : null}
      {!isGameStarted && !howToPlay ? <VideoWait /> : null}
      {isGameStarted ? (
        <Map>
          <div>
            <Money style={{ fontSize: '14px', opacity: '0.2' }}>Заработано</Money>
            <Money>{score}/{currentBag?.capacity}D</Money>
            <Money style={{ opacity: '0.2', fontSize: '30px' }}>{gameTime} сек</Money>
            <PauseBTN onClick={pause}>ПАУЗА</PauseBTN>
          </div>
          <Money style={{ color: 'red' }}>
            <span className='hp' style={{ flexDirection: 'column', alignItems: 'end', justifyContent: 'space-between', display: 'flex', opacity: '0.6' }}>
              {Array.from({ length: 3 }).map((_, index) => (
                currentBag?.today.hp_day > index ? <HeartRedIcon key={index} width={15} /> : <HeartIcon key={index} width={15} />
              ))}
            </span>
          </Money>
        </Map>
      ) : null}
      <canvas
        ref={canvasRef}
        style={{ zIndex: 1,  }}
        width={window.innerWidth}
        height={window.innerHeight}
      />
      {!isGameStarted && !howToPlay ? (
        <StartGame style={{ zIndex: 100 }}>
          {countdown > -1 ? (
            <div style={{ color: '#000' }}>{countdown}</div>
          ) : (
            <>
              {currentBag ? (
                <GradientText type="button" onClick={startGame}>НАЧАТЬ ИГРУ</GradientText>
              ) : (
                !loading ? <GradientText type="button" style={{ filter: 'grayscale(100%)', opacity: 0.6, fontSize: 24 }}>ЗАКОНЧИЛИСЬ ЖИЗНИ</GradientText> : <GradientText type="button" style={{ filter: 'grayscale(100%)', opacity: 0.6, fontSize: 24 }}>ЗАГРУЗКА ...</GradientText>
              )}
              <GradientTextBlack type="button" onClick={() => navigate(`/edu?token=${token}`)}>пройти обучение</GradientTextBlack>
            </>
          )}
        </StartGame>
      ) : null}
      {activePod && !isGameStarted && !howToPlay ? (
        <ChooseYourBagToPlay>
          <Picker
            style={{
              width: '250px',
              maskImage: 'linear-gradient(to top, transparent, transparent 5%, white 50%, white 50%, transparent 95%, transparent)',
              userSelect: 'none',
              zIndex: 100
            }}
            className='pickerBlock'
            value={pickerValue}
            itemHeight={100}
            onChange={(value, key) => updateBagActive(value)}
          >
            <Picker.Column key='bag' name='bag'>
              {user?.mypods?.map(option => (
                option?.today?.hp_day >= 1 ? (
                  <Picker.Item key={option.id} value={option.id}>
                    {({ selected }) => (
                      <PickerChooseItem selected={selected}>
                        <Img style={{ height: '65px', transition: 'all 0.3s ease-in-out' }} src={option?.image} />
                        <div className='info-bag'>
                          <div className='line-2'>
                            <span className='name'>{option.name}</span>
                            <span className='hp'>
                              {Array.from({ length: 3 }).map((_, index) => (
                                option.today.hp_day > index ? <HeartRedIcon width={15} key={index} /> : <HeartIcon key={index} width={15} />
                              ))}
                            </span>
                          </div>
                          <span className='capacity'>{option?.today?.value}/{option?.capacity} D</span>
                        </div>
                      </PickerChooseItem>
                    )}
                  </Picker.Item>
                ) : null
              ))}
            </Picker.Column>
            {countdown > -1 ? (
              <div style={{
                position: 'absolute',
                background: 'rgba(255,255,255,1)',
                maskImage: 'radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.2) 70%, rgba(255,255,255,0) 100%)',
                height: '100%', width: '100%'
              }} />
            ) : null}
          </Picker>
        </ChooseYourBagToPlay>
      ) : null}
    </CanvasWrapper>
  );
};

export default GameCanvas;
