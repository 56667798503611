import React, {ReactNode,useRef} from 'react';
import styled from "styled-components";


import anim1 from "../../../assets/video/amim1.mp4";
import anim2 from "../../../assets/video/anim2.mp4";


function VideoWait({...props}: {}) {
    const videoRef = useRef<HTMLVideoElement>(null);
    const onEnd = () => {
        setTimeout(() => {
            videoRef.current?.play();
              videoRef.current?.setAttribute("src", videoRef.current?.getAttribute("src") === anim1 ? anim2 : anim1);
              videoRef.current?.load();

          }, 15000); // 2 seconds delay
    }
    return (
        <video
        ref={videoRef}
        autoPlay
        muted
        playsInline
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          objectFit: "cover",
          zIndex: 0,
        }}
        onEnded={() => {
          onEnd()
        }}
        >
      <source src={anim1} type="video/mp4" />
      
      Your browser does not support the video tag.
      </video>
    );
}

export default VideoWait;
