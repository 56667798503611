import React, {Suspense} from 'react';
import i18next from 'i18next';
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Canvas} from "@react-three/fiber";
import {Link, useSearchParams} from "react-router-dom";
import styled from "styled-components";
import refBag from '../../assets/images/ref.png'
import {useAppState} from "../../store/app/hooks";
import {
    Black,
    Cell,
    CellGradient,
    CellRainbowBorder,
    CodeBlockBarcode,
    StatBlock,
    Product,
    ProductDataPhoto,
    ProductInfo,
    ProductInfoData, ProductInfoDataBtn,
    ProductInfoDataRow, ProductInfoDataRowBattery,
    ProductInfoDataRowNumber, ProductInfoDataRowPrice, ProductInfoDataRowText,
    ProductInfoDataRowModel,
    CodeBlockCode,
    CodeBlockCodeKey,
    ContainerBlack, IphoneBlock,
    LabelRainbow,
    LevelBlack,
    Photo,
    ProductName,
    ProductText,
    UpgradeBlock,
    UpgradeBlockTable,
    UpgradeBlockTableTh,
    UpgradeBlockText,
    UpgradeBlockTitle,
            Statistics, 
            StatisticsItem,
    WillShare,
    WillShareLoad, WillShareLoadVolume
} from './styled'
import photo1 from '../../assets/images/photo-1.png'
import photo from '../../assets/images/photo.png'
import barcode from '../../assets/images/barcode.svg'
import net from '../../assets/images/net.svg'
import arrow from '../../assets/images/arrow_up-gold.svg'
import iphone1 from '../../assets/images/iPhone-14-Pro_1.png'
import iphone2 from '../../assets/images/iPhone-14-Pro_2.png'
import load from '../../assets/images/load.svg'
import RowComponentTeam from "../../components/RefComponents/RowComponentTeam";
import RowComponent from "../../components/RefComponents/RowComponent";

import {refs} from "../../config/refs";
import {useRefAction, useRefState} from "../../store/ref/hooks";
import {ArrowIcon} from "../../UI/Svg";

const HeadphoneModelComponent = React.lazy(() => import('../../components/RefComponents/HeadphoneModelComponent'));

const CanvasWrapper = styled.div`
  height: 328px;
  width: 100%;
`

const ButtonBack = styled(Link)`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  background: #FAFAFA;
  width: 53px;
  height: 53px;
  outline: none;
`
function BlackLevelView() {
    const {link, level, click,reg, ref, price,team,secret, koef, parent} = useRefState()

    const {onShowModal} = useRefAction()
    const {token} = useAppState()
    const [searchParams] = useSearchParams()
    const fromWeb = searchParams.get('web')

    const openTg = () => {
        window.open('tg://resolve?domain=uranmusic_auth_bot','_blank')
    }
    const DownloadFiles = () => {
        window.open('https://disk.yandex.ru/d/94uR_DG0ZBduFQ','_blank')
    }
    return (
        <ContainerBlack>
            
            <LevelBlack>
            {
                    fromWeb ? (
                        <ButtonBack style={{transform: 'rotate(180deg)'}} to={`/edu?token=${token}`}><ArrowIcon
                            width={32} height={32}/></ButtonBack>
                    ) : (null)
                }
                <WillShare style={{paddingRight: 0, paddingBottom:0}}>
                    <h3>{i18next.t('blacklevel.welcome')}</h3>
                    <ProductText style={{color: "#fff"}}>{i18next.t('blacklevel.model_r')} <strong>{i18next.t('blacklevel.mod_r')}</strong>, {i18next.t('blacklevel.about_r')} </ProductText>
                </WillShare>
                <Photo>
                    <img src={refBag} alt=""/>
                    {/* <Suspense fallback={<img src={photo1} alt=""/>}>
                        <CanvasWrapper>
                            <Canvas>
                                <HeadphoneModelComponent objName='BO'/>
                            </Canvas>
                        </CanvasWrapper>
                    </Suspense> */}
                </Photo>
                <Black >
                    <CopyToClipboard onCopy={() => window.alert('Скопировано 👌')} text={link}>
                        <CodeBlockCode isBlack style={{width:'80%'}}>
                            <CodeBlockCodeKey>{link.length > 24 ? `${link.slice(30,40)}...` : ''}</CodeBlockCodeKey>
                            <img style={{marginLeft:10}} src={net} alt=""/>
                        </CodeBlockCode>
                    </CopyToClipboard>

                    <CodeBlockBarcode isBlack onClick={onShowModal}>
                        <img src={barcode} alt=""/>
                    </CodeBlockBarcode>

                </Black>
                <ProductText style={{color:'gray',fontSize:'12px', lineHeight:1, fontStyle: 'italic', maxWidth: 230, margin: '-35px auto'}}>
                    {i18next.t('blacklevel.ind_link')}
                </ProductText>
                    
                <Black>
                    <CopyToClipboard  onCopy={() => window.alert('Скопировано 👌')} text={`secret ${secret}`}>
                        <CodeBlockCode isBlack style={{width:'100%'}}>
                            <CodeBlockCodeKey style={{fontSize: 22}}>{link.length > 24 ? `secret ${secret}` : ''}</CodeBlockCodeKey>
                            <img style={{marginLeft:10}} src={net} alt=""/>
                        </CodeBlockCode>
                    </CopyToClipboard>
                    
                </Black>
                <Black style={{margin:'-35px auto'}}>
                    <ProductText style={{color:'gray',fontSize:'12px', lineHeight:1, fontStyle: 'italic'}}>
                    {i18next.t('blacklevel.code')}
                    </ProductText>
                </Black>
                <Black >

                        <CellRainbowBorder onClick={() => {
                            navigator.clipboard.writeText('https://t.me/+dx23MMU0Y0Q2NDMy').then(() => {
                                window.alert('Скопировано 👌');
                            });
                        }} style={{width: '100%', height: 55,padding:15}}>https://t.me/+dx23MMU0Y0Q2NDMy</CellRainbowBorder>

                </Black>
                                                
                <ProductText style={{color:'gray',fontSize:'12px', lineHeight:1, fontStyle: 'italic', maxWidth: 230, margin: '-35px auto 0 auto'}}>
                {i18next.t('blacklevel.private_channel')}
                </ProductText>
                
                <Statistics style={{paddingRight: 0, paddingBottom:0}}>
                    <h3>{i18next.t('blacklevel.statistic')}</h3>
                    <StatisticsItem>
                        <span>{i18next.t('blacklevel.link_page')}</span>
                        <i>{click} {i18next.t('blacklevel.people')}</i>
                    </StatisticsItem>
                    <StatisticsItem>
                        <span>{i18next.t('blacklevel.registration')}</span>
                        <i>{reg} {i18next.t('blacklevel.people')}</i>
                    </StatisticsItem>
                    <StatisticsItem>
                        <span>{i18next.t('blacklevel.user')}</span>
                        <i>{ref} {i18next.t('blacklevel.people')}</i>
                    </StatisticsItem>
                    <StatisticsItem>
                        <span>{i18next.t('blacklevel.price')}</span>
                        <i>{price} $</i>
                    </StatisticsItem>
                    {/* {
                        koef<0.9 ? (
                            <StatisticsItem>
                                <span>{i18next.t('blacklevel.profitability_headphones')} </span>
                                <i>{Math.round(3*koef*100)/100}%/ {i18next.t('blacklevel.day')} </i>
                            </StatisticsItem>
                        ):(
                            <StatisticsItem>
                                <span>{i18next.t('blacklevel.profitability')}</span>
                                <i>{Math.round(3*koef*100)/100}%/ {i18next.t('blacklevel.day')} </i>
                            </StatisticsItem>

                        )
                    } */}
                    
                    <StatisticsItem>
                        <span>{i18next.t('blacklevel.ref')}</span>
                        {parent ? (<i> {parent} </i>) : (<i> {i18next.t('blacklevel.no_ref')} </i>)}
                        
                    </StatisticsItem>
                </Statistics>


                <UpgradeBlock>
                    <UpgradeBlockTitle>
                        <p>{i18next.t('blacklevel.upgrade')}</p>
                        <img src={arrow} alt=""/>
                    </UpgradeBlockTitle>
                    <UpgradeBlockText>
                    {i18next.t('blacklevel.upgrade_R')}
                    </UpgradeBlockText>
                     

                    <UpgradeBlockTable>


                        <UpgradeBlockTableTh>
                            <Cell
                                style={{borderRadius: 10, width: 124, height: 91, background: '#3C3C3C'}}>
                                {i18next.t('blacklevel.invite')}
                            </Cell>
                            <Black
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '59%',
                                    flex: 1,
                                    justifyContent: 'space-between',
                                    gap: '5px 3px'
                                }}>
                                <CellGradient
                                     style={{
                                         width: '100%',
                                         display: 'flex',
                                         alignItems: 'center',
                                         justifyContent: 'center'
                                     }}>{i18next.t('blacklevel.profit')}
                                </CellGradient>
                                <CellRainbowBorder>{i18next.t('blacklevel.per_day')}</CellRainbowBorder>
                                <CellRainbowBorder>{i18next.t('blacklevel.per_month')}</CellRainbowBorder>
                            </Black>
                        </UpgradeBlockTableTh>
                        {
                            refs.map((item, id) => <RowComponent key={`Ref-${id}`} {...item}/>)
                        }
                    </UpgradeBlockTable>
                </UpgradeBlock>

                
                {
                    team.length > 0 ? (
                        <UpgradeBlock>
                            <UpgradeBlockTitle>
                                <p>{i18next.t('blacklevel.team')}</p>
                            </UpgradeBlockTitle>
                            <UpgradeBlockTable>
                                <UpgradeBlockTableTh>
                                    <Cell
                                        style={{borderRadius: 10, width: 124, background: '#3C3C3C'}}>
                                        {i18next.t('blacklevel.id')}
                                    </Cell>
                                    <Black
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            width: '66.66%',
                                            flex: 1,
                                            justifyContent: 'space-between',
                                            gap: '5px 3px'
                                        }}>
                                        <CellRainbowBorder>{i18next.t('blacklevel.headphones')}</CellRainbowBorder>
                                        <CellRainbowBorder>{i18next.t('blacklevel.date')}</CellRainbowBorder>
                                    </Black>
                                </UpgradeBlockTableTh>
                                {
                                    team.map((item, id) => <RowComponentTeam 
                                        {...item}
                                    />)
                                }

                                
                                <div style={{color: 'gray', fontSize: 12, marginTop: 10}}>
                                {i18next.t('blacklevel.comment')}
                                </div>
                                
                            </UpgradeBlockTable>
                        </UpgradeBlock>
                    ):(null)
                }
                

              


                {/* <WillShare>
                    <h3>{i18next.t('blacklevel.share')}</h3>
                    <ProductText style={{color: '#fff'}}>{i18next.t('blacklevel.offer')}</ProductText>
                  
                </WillShare> */}

                {/* <WillShareLoad>
                        <button type='button'  onClick={() => {
                            }}
                            >
                            <img src={load} alt=""/>
                            <LabelRainbow>{i18next.t('blacklevel.download')}</LabelRainbow>
                        </button>
                        <WillShareLoadVolume>{i18next.t('blacklevel.clarify')}</WillShareLoadVolume>
                    </WillShareLoad> */}
{/* 
                <IphoneBlock>
                    <img src={iphone1} alt=""/>
                    <img src={iphone2} alt=""/>
                </IphoneBlock> */}

            </LevelBlack>
        </ContainerBlack>
    );
}

export default BlackLevelView;
