import React, {useState,useEffect} from 'react';
import i18next from 'i18next';
import styled from "styled-components";
import { on } from '@telegram-apps/sdk';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider, {Settings} from "react-slick";
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';
import {
    BlockComponent,
    HeaderComponent,
    HeadphoneItemComponent, UserHeadphoneComponent,
    UserStatisticComponent,
    TokenComponent
} from"../components/EduComponents";
import {useUserData, useUserState} from"../store/user/hooks";
import {ArrowIcon} from"../UI/Svg";
import Preloader from"../views/RefViews/Preloader";
import PreloaderMax from"../views/RefViews/PreloaderMax";
import MutedHeadphoneModal from '../components/EduComponents/Modals/MutedHeadphoneModal';
import SellHeadphoneModal from '../components/EduComponents/Modals/SellHeadphoneModal';
import { IUserPod } from '../store/user/types';
import SmallHeadphoneModal from '../components/EduComponents/Modals/SmallHeadphoneModal';
import BannersComponent from '../components/EduComponents/BannersComponent';
import BuyHeadphoneModal from '../components/EduComponents/Modals/BuyHeadphoneModal';
import Footer from"../components/Footer";
import Header from"../components/Header";


const Wrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
  max-width: 1200px;
  height: 100vh;
  margin: 0 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  padding-bottom: 100px;
`

const BlockStyled = styled(BlockComponent)`
  padding: 0;

  & > h3 {
    padding-left: 15px;
  }
`

const List = styled.div`
  margin-top: 17px;
`

const ListUser = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  align-items: stretch;
  align-content: center;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 17px;
  max-width: unset;
  padding-right: 16px;
  padding-left: 16px;

  &::-webkit-scrollbar {
    display: none;
  }
`

const BlockVideo = styled.div`
    
    overflow: hidden;
    min-width: 450px;
`

const settings: Settings = {
    dots: false,
    infinite: false,
    // initialSlide: 0,
    speed: 500,
    swipe: true,
    arrows: false,
    slidesToShow: 3,
    centerMode: false,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 990,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 568,
            settings: {
                slidesToShow: 1.05,
            }
        },
    ]
};

function Index() {
    useUserData()
    const {user} = useUserState()
    const [loading, setLoading] = useState(false);
    const [selectedSellPod, setSelectedSellPod] = useState<IUserPod>();
    const [selectedUserPod, setSelectedUserPod] = useState<IUserPod>();
    const [selectedMutedPod, setSelectedMutedPod] = useState<IUserPod>();
    const [selectedBuyPod, setSelectedBuyPod] = useState<any>();


    // const [vieport, setVieport] = useState('');
    const mutePodsFiltered = user?.mutepods.filter((mutepod) => !user.mypods.some((mypod) => mutepod.slug === mypod.slug));



        // Start listening to "viewport_changed" event. Returned value
        // is a function, which removes this event listener.
        // const removeListener = on('viewport_changed', payload => {
        //     setVieport(`Viewport changed: ${payload}`);
        // });

        
    useEffect(() => {
        let interval: any
        if (loading) {
            interval = setInterval(() => {
                setLoading(false)
            }, 5000)
        }
    }, [loading])

    return (
        <Wrapper>
            <div style={{ width: '100%', zIndex: 100}}>
                <Header absolute={false} show='game'/>
            </div>

            <HeaderComponent setLoading={setLoading}/>

            <BlockStyled title={i18next.t('body_market.your_headphone')}>

                <ListUser>
                    <UserStatisticComponent />
                    {/* <TokenComponent  setLoading={setLoading} /> */}
                    {user?.mypods.map((pod) => (
                        <UserHeadphoneComponent 
                            
                            key={`UserPod-${pod.id}`} 
                            onClick={() => setSelectedUserPod(pod)}
                            onSell={() => setSelectedSellPod(pod)}
                            canSell={user?.canSell}
                            {...pod}
                        />
                    ))}

{/*                     
                    {user?.mutepods.map((pod) => (
                        <UserHeadphoneComponent 
                                key={`MutedPod-${pod.id}`}
                                muted
                                onClick={() => setSelectedMutedPod(pod)}
                                {...pod}
                            />
                    ))} */}


                    {/* <BlockVideo style={{
                        borderBottomLeftRadius: 30,  
                        borderBottomRightRadius: 30,
                        borderTopRightRadius: 30,
                        borderTopLeftRadius: 30,
                        overflow:'hidden',
                    }}>
                        <iframe style={{borderRadius:30,overflow:"hidden",}} width='100%' height="100%" src={i18next.t('body_market.video')}
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen/>
                     </BlockVideo> */}
                </ListUser>
                
                
                {/* modals for specific user pod */}
                {createPortal(<AnimatePresence>
                    {selectedUserPod ? (
                        <SmallHeadphoneModal 
                        {...selectedUserPod} 
                        onClose={() => setSelectedUserPod(undefined)} 
                    />) : null}
                </AnimatePresence>, document.body)}
                
                {createPortal(<AnimatePresence>
                    {selectedBuyPod ? (
                        <BuyHeadphoneModal 
                        {...selectedBuyPod} 
                        uidUser={user?.uid}
                        onClose={() => setSelectedBuyPod(undefined)} 
                    />) : null}
                </AnimatePresence>, document.body)}

                {/* modals for specific muted pod */}
                {createPortal(<AnimatePresence>
                    {selectedMutedPod && selectedMutedPod.slug === 'uran_muted' ? (
                        <MutedHeadphoneModal 
                        {...selectedMutedPod} 
                        onClose={() => setSelectedMutedPod(undefined)} 
                    />) : null}
                </AnimatePresence>, document.body)}
                
                {createPortal(<AnimatePresence>
                    {selectedSellPod ? (
                        <SellHeadphoneModal 
                        {...selectedSellPod} 
                        onClose={() => {
                            setSelectedSellPod(undefined);
                        }
                        } 
                    />) : null}
                </AnimatePresence>, document.body)}

            </BlockStyled>
            <BlockStyled title={i18next.t('body_market.store')} >
                <ListUser>
                    {user?.pods.map((pod) => <HeadphoneItemComponent 
                        setLoading={setLoading}
                        onClick={() => setSelectedBuyPod(pod)}
                        key={`Pod-${pod.id}`} {...pod}/>)}
                </ListUser>
            </BlockStyled>
            {
                (user?.pods  ) ? (null)  : (<Preloader /> )
            }
            {
                ( !loading) ? (null)  : (<PreloaderMax /> )
            }

            {/* modals for banners */}
            {user ? <BannersComponent banners={user.unread_board} /> : null}
        </Wrapper>
    );
}

export default Index;
