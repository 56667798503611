import React, {useState} from 'react';
import { transform } from 'typescript';
import  {useNavigate} from "react-router-dom";
import styled from "styled-components";
import { openTelegramLink,openLink } from '@telegram-apps/sdk';
import {InstagramIcon, YouTubeIcon, TelegramIcon, BurgerIcon,ArrowIcon} from "../UI/Svg";
import {useAppState} from "../store/app/hooks";
   

// Стили компонентов
const MenuButton = styled.div<{ absolute: boolean }>`
  position: ${({ absolute }) => (absolute ? 'fixed' : 'relative')};
  top: 75px;
  left: 0px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  items-align: center;
`;

const MenuContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  margin: 2%;
  top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  left: 0;
  width: calc(100% - 4%);
  border-radius: 15px;
  background-color: #fff;
  backdrop-filter: blur(10px);
  color: #000;
  z-index: 1000;
  padding-top: 10px;
  transition: top 0.3s;
  
`;

const MenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
`;

const MenuTitle = styled.span`
  font-size: 25px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.2);
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #000;
  font-size: 20px;
  cursor: pointer;
`;

const MenuItems = styled.ul`
  display: flex;
  flex-direction: column;
  items-align: center;
  width: 100%;

  margin: 0;
  margin-top: 25px;
  padding: 0;
  padding-left: 10px;
  gap: 16px;
  font-size: 18px;
  padding-bottom: 15px;
`;

const MenuItem = styled.li<{ isCurrentPage: boolean }>`
  font-weight: ${({ isCurrentPage }) => (isCurrentPage ? '900' : 'normal')};
  color: rgba(10, 10, 10, 0.8);
  place-content: center ;
  -moz-box-pack: justify;
  item-align: center;
  font-size: 15px;
  padding: 5px 10px;
  line-height: 10px;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: #000;
  }
`;

const Arrow = styled.span`
  font-size: 16px;
`;
const Blur = styled.div`
    position: absolute;
    height: 100vh;
    top: 0;
    left: 0;
    width: 100%;
    backdrop-filter: blur(22px);
    background: rgba(14, 14, 14, 0.4);
    z-index: 999;
`;
const Border = styled.div`
    border-bottom: 1px solid rgba(0,0,0,0.05);
    width: 100%;
    margin-top: 10px;

`;

function Header({absolute = true, show=null}) {
    const {token} = useAppState()
    const navigate = useNavigate();
  const currentPath = window.location.pathname;

  const isCurrentPage = (path: string): boolean => currentPath === path;
    const moveTo = (path: string) => {
      navigate(`${path}?token=${token}`);
    };

    

    const openSupport= () => {
      const link = `https://t.me/partiyadengi`
      if (openTelegramLink.isAvailable()) {
        openTelegramLink(link );
      }
     }
  
     const openChanel= () => {
      const link = `https://t.me/partiyadengi`
      if (openTelegramLink.isAvailable()) {
        openTelegramLink(link );
      }
     }


     const openYouTube = () => {
      const link = 'https://www.youtube.com/@Partiyadebgi'
      if (openLink.isAvailable()) {
        openLink(link,{
          tryInstantView: true
        });
      }
      else{
        console.log('cannot open')
      }
  }  

  const openInsta = () => {
      const link = 'https://www.instagram.com/partia_dengi?igsh=dWZkMHBjMHJkbHJr'
      if (openLink.isAvailable()) {
        openLink(link,{
          tryInstantView: true
        });
      }
      else{
        console.log('cannot open')
      }
  }  


    const [isOpen, setIsOpen] = useState(false); // Состояние меню: открыто или закрыто

   
    const toggleMenu = () => {
        setIsOpen(!isOpen);
      };
    return (
        <div >
                {!isOpen && (
                    <MenuButton absolute={absolute} >
                      <button style={{background:'rgba(0,0,0,0)',padding:'0 20px'}} type="button" onClick={toggleMenu} aria-label="Toggle Menu"><BurgerIcon/></button>
                      {
                        show === 'shop' ?  <button type="button" onClick={()=>moveTo('/')}  aria-label="Toggle Menu" style={{color:'rgba(0,0,0,0.5)', fontSize:'18px', background:'rgba(0,0,0,0)'}}>
                        Перейти в магазин <ArrowIcon/> 
                      </button> : null
                      }


                      {
                        show === 'game' ?  <button type="button" onClick={()=>moveTo('/game')} aria-label="Toggle Menu" style={{color:'rgba(0,0,0,0.5)', fontSize:'18px', background:'rgba(0,0,0,0)'}}>
                        Перейти в игру <ArrowIcon/> 
                      </button> : null
                      }

                      
                     
                      
                    </MenuButton>
                )}
                {isOpen && (
                        <Blur onClick={toggleMenu}>
                            <MenuContainer isOpen={isOpen}>
                                <MenuHeader>
                                    <MenuTitle>Денежное меню</MenuTitle>
                                    <CloseButton onClick={toggleMenu}>✕</CloseButton>
                                </MenuHeader>
                                    <span style={{ marginLeft:'20px',fontSize: '12px',opacity:0.3,color:'#000'}}>Проект находится в бета. Официальный запуск 6 февраля.</span>
                                    <MenuItems>
                                    <MenuItem isCurrentPage={isCurrentPage('/')} onClick={()=>moveTo('/')}>
                                        Личный кабинет
                                        <Border/>
                                    </MenuItem>
                                    <MenuItem 
                                      isCurrentPage={isCurrentPage('/game')}
                                      onClick={()=>moveTo('/game')}>Игра Партии <Border/></MenuItem>
                                    <MenuItem isCurrentPage={isCurrentPage('/director')} onClick={()=>moveTo('/director')}>Совет Директоров <Border/></MenuItem>
                                    <MenuItem isCurrentPage={isCurrentPage('/ref')} onClick={()=>moveTo('/ref')}>Реферальная программа <Border/></MenuItem>
                                    <MenuItem isCurrentPage={false} onClick={()=>window.open('https://theosnovatel.com/','_self')}>Магазин Партии <Border/></MenuItem>
                                    <MenuItem isCurrentPage={false} onClick={() => openSupport()}>Служба Заботы <Border/></MenuItem>
                                    <MenuItem isCurrentPage={false} style={{opacity:'0.4',paddingTop:'15px', display:'flex', justifyContent:'start',gap:'20px', alignItems:'center',zIndex:'99'}}>
                                        <button onClick={()=>openChanel()} type='button' aria-label="Open Telegram Channel" style={{ background: 'transparent' }}><TelegramIcon height={25} /></button>
                                        <button onClick={() => openYouTube()} type='button' aria-label="Open YouTube Channel" style={{ background: 'transparent' }}><YouTubeIcon height={25} /></button>
                                        <button onClick={() => openInsta()} type='button' aria-label="Open Instagram" style={{ background: 'transparent' }}><InstagramIcon height={25} /></button>
                                        {/* <button onClick={()=>moveTo('/game-canvas')} type='button' aria-label="Open Instagram" style={{ background: 'transparent',color:'black',padding:10 }}>.</button> */}
                                    </MenuItem>
                                </MenuItems>
                            </MenuContainer>
                    </Blur>
                )}
        </div>
    );
};


export default Header;